import './modal.css'

import * as React from 'react'

interface ModalProps {
  visible?: boolean
}

const Modal: React.FunctionComponent<ModalProps> = ({ visible, children }) => {
  if (!visible) return null
  return (
    <div className="fixed bg-gray-900 bg-opacity-75 top-0 right-0 left-0 bottom-0 flex justify-center items-center  overflow-y-auto">
      <div
        className={`modal bg-white w-full max-w-3xl rounded py-10 md:py-16 px-8 md:px-20 m-3 shadow-lg`}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
