import React from "react"

import Chooser from "../components/chooser"
import Layout from "../components/layout"
import Modal from "../components/modal"
import SEO from "../components/seo"
import IconLoading from "../images/icon-loading.svg"
import LogoCBRDoc from "../images/logo-cbrdoc-horizontal-slogan.svg"
import LogoDocBrasil from "../images/logo-documento-no-brasil.svg"

const Confianca = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center">
      <div className="flex items-center pb-4 md:px-4 md:pb-0">
        <span className="icon-shield-check text-2xl text-success mr-1" />
        <span className="text-gray-700 text-xs">100% seguro</span>
      </div>
      <div className="flex items-center pb-4 md:px-4 md:pb-0">
        <span className="icon-medal-empty text-2xl text-success" />
        <span className="text-gray-700 text-xs">Entrega garantida</span>
      </div>
      <div className="flex items-center pb-0 md:px-4 md:pb-0">
        <span className="icon-file-image text-2xl text-success mr-1" />
        <span className="text-gray-700 text-xs">
          Prévia digitalizada sempre a mão
        </span>
      </div>
    </div>
  )
}

export type RedirectToTypes = "cbrdoc" | "docbrasil" | undefined

const IndexPage = () => {
  const [redirectTo, setRedirectTo] = React.useState<RedirectToTypes>()

  const navigateTo = (name: RedirectToTypes) => {}

  return (
    <Layout>
      <SEO title="Home" />
      <section className="text-gray-700">
        <div className="max-w-3xl mx-auto px-5 py-4 md:py-16 shadow-md mb-8 md:mb-0 md:shadow-none">
          <div className="flex items-center">
            <div className="flex-1 flex justify-center">
              <LogoCBRDoc className="w-full" />
            </div>
            <div className="w-px h-8 md:h-16 bg-gray-200 mx-3 md:mx-8" />
            <div className="flex-1 flex justify-center">
              <LogoDocBrasil className="w-full" />
            </div>
          </div>
        </div>
        <div className="text-gray-700 text-center px-5 pb-6 md:pb-16">
          <h1 className="text-xl md:text-3xl font-light leading-tight mb-3">
            Somos os maiores especialistas em documentação do Brasil.
          </h1>
          <p className="text-xs md:text-base max-w-2xl mx-auto md:font-semibold">
            Tenha acesso a milhares de cartórios e outras instituições em poucos
            cliques. Atendemos pessoas físicas, jurídicas e advogados.
          </p>
        </div>
      </section>
      <section className="bg-gray-700">
        <div className="max-w-3xl mx-auto py-6 md:py-16 px-5">
          <h2 className="text-white text-lg md:text-2xl text-center leading-tight">
            Selecione seu perfil para que possamos te direcionar:
          </h2>
          <Chooser
            onChange={name => {
              let redirectUrl = "https://documentonobrasil.com.br"
              if (name === "pessoa-fisica") {
                setRedirectTo("docbrasil")
                redirectUrl = "https://documentonobrasil.com.br"
              }
              if (name === "pessoa-juridica") {
                setRedirectTo("cbrdoc")
                redirectUrl = "https://cbrdoc.com.br"
              }
              if (name === "advogado") {
                setRedirectTo("cbrdoc")
                redirectUrl = "https://cbrdoc.com.br"
              }
              setTimeout(() => {
                window.location.href = redirectUrl
              }, 4000)
            }}
          />
          <Modal visible={redirectTo !== undefined}>
            <IconLoading className="animate-spin text-gray-600 text-4xl mx-auto mb-3" />
            <span className="block w-full font-bold text-lg md:text-xl text-gray-700 text-center">
              Aguarde, você está sendo redirecionado para...
            </span>
            <div className="pt-6 pb-10 md:pt-10 md:pb-12">
              {redirectTo === "cbrdoc" && (
                <LogoCBRDoc className="mx-auto w-full" />
              )}
              {redirectTo === "docbrasil" && (
                <LogoDocBrasil className="mx-auto w-full" />
              )}
            </div>
            <div className="border-b border-t border-gray-200 py-5">
              <Confianca />
            </div>
          </Modal>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-3xl mx-auto py-5 px-5 md:px-0">
          <Confianca />
        </div>
      </section>
      <section className="mb-10 md:mb-20">
        <div className="max-w-5xl mx-auto py-12 md:py-16 px-6">
          <div className="flex flex-col md:flex-row text-gray-700">
            <div className="w-full md:w-1/2 md:pr-8 pb-10 md:pb-0">
              <h2 className="font-light text-2xl mb-5 leading-snug">
                Para quem é a CBRdoc?
              </h2>
              <p className="text-sm">
                Para empresas, advogados e profissionais liberais. Precisam de
                um sistema de gestão de documento, e faça solicitações
                recorrente de documentos. Com a CBRdoc é possível fazer a
                solicitação, busca, gestão e análise de seus documentos.
                Reduzindo prazos e melhorando seus processos operacionais com
                que existe de melhor em tecnologia.
              </p>
            </div>
            <div className="w-full md:w-1/2 md:pl-8">
              <h2 className="font-light text-2xl mb-5 leading-snug">
                Para quem é o Documento no Brasil?
              </h2>
              <p className="text-sm">
                Uma plataforma simples e fácil para você solicitar qualquer
                documento no Brasil. Você solicita sem criar com conta, em pouco
                menos de 5 minutos. Acompanha todo o recebimento do seu
                documento. E ainda tem a vantagem de receber a prévia
                digitalizada de seus documentos. Tenha rapidamente e seguro os
                documentos que precisa.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
