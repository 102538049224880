import './footer.css'

import PropTypes from 'prop-types'
import React from 'react'

const Footer = () => (
  <footer className="main-footer">
    <div className="footer-content">
      <div className="footer-row p-30">
        <div className="footer-contact-links">
          <div className="footer-title">
            <h2>Ainda tem dúvidas?</h2>
            <h3>Fale com a gente</h3>
          </div>
          <div className="contact-link">
            <span className="icon-bubble-dots"></span>
            <div>
              <h3>Chat On-line</h3>
              <h4>Segunda a sexta das 9 às 18h</h4>
            </div>
          </div>
          <div className="contact-link">
            <span className="icon-telephone"></span>
            <div>
              <h3>11 4862 0000</h3>
              <h4>Telefone</h4>
            </div>
          </div>
          <div className="contact-link">
            <span className="icon-whatsapp"></span>
            <div>
              <h3>11 99319 4844</h3>
              <h4>WhatsApp</h4>
            </div>
          </div>
          <div className="contact-link">
            <span className="icon-envelope"></span>
            <div>
              <h3>contato@documentonobrasil.com.br</h3>
              <h4>E-mail</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-row">
        <div className="footer-secondary-links">
          <a href="https://central.documentonobrasil.com.br/" target="_blank">
            Central do Cliente
          </a>
          <a href="https://documentonobrasil.com.br/quem-somos">Quem somos</a>
          <a href="https://documentonobrasil.com.br/faleconosco">
            Fale conosco
          </a>
          <a
            href="https://documentonobrasil.com.br/empresas"
            target="_blank"
            className="hidden-inline-phone-down"
          >
            Para empresas
          </a>

          <a href="https://documentonobrasil.com.br/politica">
            Termos e políticas
          </a>
        </div>
      </div>
      <div className="footer-row copyright-row">
        <div className="footer-secure">
          <div className="serasa">
            <img src={require("../images/serasa-distribuidor.png")} />
          </div>
          <div className="security">
            <img src={require("../images/icon-seguranca.png")} />
          </div>
        </div>
        <div className="footer-copyright">
          <img src={require("../images/cb-rdoc.png")} className="cbrdoc" />
          <span className="copyright">
            ©CBRdoc. Todos os direitos reservados.
          </span>
          <span className="cnpj">34.079.555/0001-92</span>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
