import * as React from 'react'

import IconBriefcase from '../images/icon-briefcase.svg'
import IconBusiness from '../images/icon-business.svg'
import IconCheckCircle from '../images/icon-check-circle.svg'
import IconFamily from '../images/icon-family.svg'

type IconTypes = "family" | "business" | "briefcase"

interface IconProps extends Partial<Omit<HTMLElement, "type">> {
  type: IconTypes
}

const Icon: React.FC<IconProps> = ({ type, ...baseProps }) => {
  const iconTypes: Record<IconTypes, any> = {
    family: IconFamily,
    business: IconBusiness,
    briefcase: IconBriefcase,
  }

  const Icon = iconTypes[type]

  return <Icon {...baseProps} />
}

interface ChooserItemProps {
  icon: IconTypes
  label: string
  active: boolean
  disabled?: boolean
  onClick: () => void
}

export const ChooserItem: React.FC<ChooserItemProps> = ({
  icon,
  label,
  active,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={`rounded-sm px-4 pt-4 pb-2 relative cursor-pointer transition-colors duration-150 ${
        active && !disabled ? "bg-primary text-white" : "bg-white text-gray-700"
      } ${disabled && !active ? "bg-gray-500" : ""}`}
      onClick={onClick}
    >
      <IconCheckCircle
        className={`absolute right-0 top-0 m-4 transition-opacity duration-150 ${
          active ? "opacity-100" : "opacity-0"
        }`}
      />
      <Icon type={icon} className="text-2xl md:text-4xl mb-3 md:mb-10" />
      <span className="text-lg md:text-xl leading-none">{label}</span>
    </div>
  )
}

type ChooseTypes = "pessoa-fisica" | "pessoa-juridica" | "advogado"

interface ChooserProps {
  onChange: (name: ChooseTypes) => void
}

const Chooser: React.FC<ChooserProps> = ({ onChange }) => {
  const [selected, setSelected] = React.useState<ChooseTypes>()
  const timeout = React.useRef<number>()

  const handleClick = (name: ChooseTypes) => {
    setSelected(name)
    clearTimeout(timeout.current)
    timeout.current = window.setTimeout(() => {
      onChange(name)
    }, 1000)
  }

  return (
    <div className="flex flex-col md:flex-row mt-4 md:mt-8">
      <div className="w-full md:w-1/3 pb-3 md:pb-0 md:px-3">
        <ChooserItem
          icon="family"
          label="Pessoa Física"
          active={selected === "pessoa-fisica"}
          disabled={
            selected && ["pessoa-juridica", "advogado"].includes(selected)
          }
          onClick={() => handleClick("pessoa-fisica")}
        />
      </div>
      <div className="w-full md:w-1/3 pb-3 md:pb-0 md:px-3">
        <ChooserItem
          icon="business"
          label="Pessoa Jurídica"
          active={selected === "pessoa-juridica"}
          disabled={
            selected && ["pessoa-fisica", "advogado"].includes(selected)
          }
          onClick={() => handleClick("pessoa-juridica")}
        />
      </div>
      <div className="w-full md:w-1/3 pb-3 md:pb-0 md:px-3">
        <ChooserItem
          icon="briefcase"
          label="Advogado"
          active={selected === "advogado"}
          disabled={
            selected && ["pessoa-fisica", "pessoa-juridica"].includes(selected)
          }
          onClick={() => handleClick("advogado")}
        />
      </div>
    </div>
  )
}

export default Chooser
